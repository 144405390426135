
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import { mask } from "vue-the-mask";
import router from "@/router";

export default Vue.extend({
  name: "Create",

  components: {
    ata: () => import("../components/order_types/ATA/Edit.vue"),
    aaij: () => import("../components/order_types/AAIJ/Edit.vue"),
    cert: () => import("../components/order_types/CERT/Edit.vue"),
    eval: () => import("../components/order_types/EVAL/Edit.vue"),
    exp: () => import("../components/order_types/EXP/Edit.vue")
  },

  directives: { mask },

  data: () => ({
    model: {} as any,
    loading: false as boolean,
    changeStatusDialog: false as boolean,
    editComponents: {
      1: "ata",
      2: "aaij",
      3: "cert",
      4: "eval",
      5: "exp"
    },
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.edit",
        disabled: true,
        href: `/orders/edit`
      }
    ],
  }),

  async beforeRouteEnter(to, from, next) {
    if (to.query.token) {
      try {
        const credentials = await API.users().checkUserByToken({
          token: to.query.token
        });

        await store.dispatch("authentication/login", credentials);
        await store.dispatch("user/set", credentials.user);
        const query = { ...to.query, token: undefined } as any;
        await router.push({ path: to.path, query });
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
    }
    next();
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      try {
        const params = {} as any;
        if (this.$route.query.hasOwnProperty("additions")) {
          params.additions = this.$route.query.additions;
        }

        const item = await API.orders().getForEdit(
          Number(this.$route.params.id),
          params
        );

        this.$nextTick(() => {
          this.fullLoaded = true;
          this.model = {
            ...item,
            parent_documents: item.parent_documents.map((item: any) => ({
              value: item.id,
              text: item.number
            }))
          };
        });
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async cloneOrder(id: string): Promise<void> {
      try {
        await this.$API.orders().cloneOrder(id);
        this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async goTo(path: string): Promise<void> {
      if (this.$route.query && Object.keys(this.$route.query).length) {
        // todo fix it after presentation
        await this.$router.replace({ query: null as any });
      }
      await this.$router.push(path);
    }
  }
});
